<template>
  <div class="withdraw-approve">
    <div class="header-title">{{ $t("Withdrawal Approval") }}</div>
    <div class="trx-body d-none d-lg-block">
      <div
        class="d-flex justify-content-center align-items-center loading-container"
        v-if="isLoading"
      >
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </div>

      <div v-else class="trx-table px-3">
        <b-table
          tbody-tr-class="transaction-rowClass"
          ref="trxTransactions"
          :fields="transactionFields"
          :items="transactions"
          responvise
          show-empty
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key == 'action' ? '80px' : '100px' }"
            />
          </template>

          <!-- th start -->
          <template v-slot:head(submitted_time)>
            <div class="table-header-content">
              <div>{{ $t("Submitted Time") }}</div>
            </div>
          </template>
          <template v-slot:head(request_id)>
            <div class="table-header-content">
              <div>{{ $t("Request ID") }}</div>
            </div>
          </template>
          <template v-slot:head(coin)>
            <div class="table-header-content">
              <div>{{ $t("Coin") }}</div>
            </div>
          </template>
          <template v-slot:head(network)>
            <div class="table-header-content">
              <div>{{ $t("Network") }}</div>
            </div>
          </template>
          <template v-slot:head(amount)>
            <div class="table-header-content">
              <div>{{ $t("Amount") }}</div>
            </div>
          </template>
          <template v-slot:head(to_address)>
            <div class="table-header-content">
              <div>{{ $t("To Address") }}</div>
            </div>
          </template>
          <template v-slot:head(request_by)>
            <div class="table-header-content">
              <div>{{ $t("Request By") }}</div>
            </div>
          </template>
          <template v-slot:head(type)>
            <div class="table-header-content">
              <div>{{ $t("Type") }}</div>
            </div>
          </template>
          <template v-slot:head(action)>
            <div class="table-header-content">
              <div>{{ $t("Action") }}</div>
            </div>
          </template>
          <!-- th end -->

          <!-- tbody start -->
          <!-- <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                                <div class="text-center">
                                    <b-form-checkbox :checked="true" size="lg" :disabled="true"></b-form-checkbox>
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-center">
                                    <b-form-checkbox :checked="false" size="lg" :disabled="true"></b-form-checkbox>
                                </div>
                            </template>
                        </template> -->

          <template #cell(amount)="row">
            <div class="text-center">
              {{ row.item.amount }}
            </div>
          </template>

          <template #cell(to)="row">
            <div>
              {{ row.item.to.substr(0, 5) }}...{{ row.item.to.substr(-5, 5) }}
            </div>
          </template>

          <template #cell(created_at)="row">
            <div>{{ dateFormatter(row.item.created_at) }}</div>
          </template>

          <template #cell(token)="row">
            <img
              class="mr-2"
              :src="row.item.image"
              alt="coin-img-savvix"
              width="20"
              height="20"
            />
            <span class="text-success">{{ row.item.token }}</span>
          </template>

          <template #cell(network)="row">
            <img
              class="mr-2"
              :src="row.item.network_image"
              alt="coin-img-savvix"
              width="20"
              height="20"
            />
            <span class="text-success">{{ row.item.network }}</span>
          </template>

          <template #cell(action)="row">
            <div class="d-flex justify-content-between">
              <b-icon-check
                scale="2"
                class="text-success"
                @click.stop.prevent="
                  showApproveWithdrawModal(row.item.id, row.item.network)
                "
              ></b-icon-check>
              <b-icon-x
                scale="2"
                class="text-danger"
                @click.stop.prevent="
                  showRejectWithdrawModal(row.item.id, row.item.network)
                "
              ></b-icon-x>
            </div>
          </template>
          <!-- tbody end -->
        </b-table>
      </div>
    </div>

    <div class="mobile d-block d-lg-none">
      <div class="accordion" role="tablist">
        <div class="d-flex px-1 py-2 font-sm">
          <!-- header -->
          <div class="col-3 text-center">Coin</div>

          <div class="col-4 text-center">Amount</div>

          <div class="col-5 text-center">Request By</div>
        </div>
        <div v-for="data in transactions" :key="data.id" class="px-1">
          <div
            class="bg-white p-2 radius-50 mt-2"
            v-b-toggle="`accordion-${data.id}`"
          >
            <div
              class="d-flex justify-content-between align-items-center relative"
            >
              <!-- <div class="d-flex align-items-center">
                <img :src="data.image" alt="coin-img" width="20" height="20" />
                <span class="ml-2">{{ data.token }} ({{ data.network }})</span>
              </div>
              <div>{{ data.amount }} {{ data.token }}</div> -->
              <!-- header -->
              <div class="col-3 text-center d-flex align-items-center">
                <img :src="data.image" alt="coin-img" width="20" height="20" />
                <span class="ml-2">{{ data.token }}</span>
              </div>

              <div class="col-4 text-center">{{ data.amount }}</div>

              <div class="col-5 text-center text-ellipsis">
                <span>{{ data.request_by }}</span>
              </div>

              <div class="position-absolute right-center mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="6"
                  viewBox="0 0 14 8"
                  fill="none"
                >
                  <path
                    d="M1.0822 1.12939e-06L7.31221 5.84751e-07L12.9222 9.43092e-08C13.8822 1.03834e-08 14.3622 1.16 13.6822 1.84L8.50221 7.02C7.67221 7.85 6.32221 7.85 5.49221 7.02L3.52221 5.05L0.312205 1.84C-0.357796 1.16 0.122205 1.21332e-06 1.0822 1.12939e-06Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
          <b-collapse
            :id="`accordion-${data.id}`"
            accordion="my-accordion"
            role="tabpanel"
          >
            <div class="bg-cs-gray pt-3 mx-2 border-top font-sm">
              <div class="px-3">
                <div class="d-flex justify-content-between py-2">
                  <div>Submit Time</div>
                  <div>{{ dateFormatter(data.created_at) }}</div>
                </div>
                <div class="d-flex justify-content-between py-2">
                  <div>Request by</div>
                  <div>{{ data.request_by }}</div>
                </div>
                <div class="d-flex justify-content-between py-2">
                  <div>Network</div>
                  <div>
                    <img
                      :src="data.network_image"
                      alt="coin-img"
                      width="20"
                      height="20"
                    />
                    <span class="ml-2">{{ data.network }}</span>
                  </div>
                </div>
                <!-- <div class="d-flex justify-content-between py-2">
                  <div>Type</div>
                  <div>{{ data.type }}</div>
                </div> -->

                <div class="d-flex justify-content-between py-2">
                  <div>Request ID</div>
                  <div>{{ data.request_id }}</div>
                </div>

                <div class="d-flex justify-content-between py-2">
                  <div>To</div>
                  <div>{{ data.to }}</div>
                </div>
              </div>

              <div class="d-flex justify-content-end py-3 border-top">
                <div class="col-6">
                  <button
                    class="btn btn-sm w-100 btn-danger w-50"
                    @click.stop.prevent="
                      showRejectWithdrawModal(data.id, data.network)
                    "
                  >
                    Reject
                  </button>
                </div>

                <div class="col-6">
                  <button
                    class="btn btn-sm w-100 btn-success"
                    @click.stop.prevent="
                      showApproveWithdrawModal(data.id, data.network)
                    "
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>

    <!-- Approve Withdraw -->
    <b-modal
      ref="approve-withdraw-modal"
      hide-footer
      :title="$t(`APPROVE WITHDRAW`)"
      centered
    >
      <form
        class="modal-form"
        @submit.stop.prevent="approveWithdraw('approve-withdraw-modal')"
      >
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <p>{{ $t("Please key in 2FA code to continue the action") }}</p>
          <v-otp-input
            ref="approveWithdrawOtpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            v-model="twoFaCode"
            input-type="number"
            @on-complete="onTwoFaComplete"
          />
          <button class="mt-5 w-50 activate-btn" v-if="!isApproving">
            Confirm
          </button>
          <button type="button" class="mt-5 w-50 activate-btn" v-else>
            <b-spinner small variant="success" label="Spinning"></b-spinner>
          </button>
        </div>
      </form>
    </b-modal>

    <!-- Reject Withdraw -->
    <b-modal
      ref="reject-withdraw-modal"
      hide-footer
      :title="$t(`REJECT WITHDRAW`)"
      centered
    >
      <form
        class="modal-form"
        @submit.stop.prevent="rejectWithdraw('reject-withdraw-modal')"
      >
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <p>{{ $t("Please key in 2FA code to continue the action") }}</p>
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            v-model="twoFaCode"
            input-type="number"
            @on-complete="onTwoFaComplete"
          />
          <button class="mt-5 w-50 activate-btn" v-if="!isRejecting">
            {{ $t("Confirm") }}
          </button>
          <button type="button" class="mt-5 w-50 activate-btn" v-else>
            <b-spinner small variant="success" label="Spinning"></b-spinner>
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import api from "../helper/api.js";
import moment from "moment";
import coinHelper from "../helper/coin.js";

export default {
  name: "Approve",
  data() {
    return {
      transactionFields: [
        { key: "created_at", label: "Time" },
        // { key: "created_at", label: "Created at" },
        { key: "request_id", label: "Request ID" },
        { key: "token", label: "Coin" },
        { key: "network", label: "Network" },
        { key: "amount", label: "Amount" },
        { key: "to", label: "To Address" },
        { key: "request_by", label: "Request By" },
        { key: "type", label: "Type" },
        { key: "action", label: "Action" },
      ],
      isLoading: true,
      // bscTransactions: [],
      // trxTransactions: [],
      // ethTransactions: [],

      // bscSelected: [],
      // trxSelected: [],
      // ethSelected: [],

      trx: {
        transactions: [],
        selected: [],
        isUpdate: false,
      },

      bsc: {
        transactions: [],
        selected: [],
        isUpdate: false,
      },

      eth: {
        transactions: [],
        selected: [],
        isUpdate: false,
      },

      transactions: [],
      twoFaCode: "",
      currentTabs: 0,
      networks: ["trx", "bsc", "eth"],
      isApproving: false,
      isRejecting: false,
      withdraw_id: [],
      selectedNetwork: "",

      testData: [
        {
          id: 1,
          created_at: "2023-12-31 12:00:00",
          request_id: "web_4f1234567898745632",
          token: "BNB",
          network: "BNB",
          image: require("../assets/icon/coins/usdt.svg"),
          network_image: require("../assets/icon/coins/usdt.svg"),
          amount: "30.00",
          to: "0x0bA39170FdAAaA84e733047b1cD6F7595C131cD0",
          request_by: "TanTanTan",
          type: "Web",
        },
      ],
    };
  },
  async mounted() {
    await this.fetchPendingTransactions();
    this.isLoading = false;
    this.currentTabs = localStorage.getItem("currentApproveTab");
  },
  methods: {
    async fetchPendingTransactions() {
      const response = await api.apiCall(
        "core",
        "GET",
        "/operators/approve-list"
      );
      this.transactions = response.message.data;
    },

    showApproveWithdrawModal(id, network) {
      this.withdraw_id = [id];
      this.selectedNetwork = network;
      // if (type == 'single') {
      //     this.$refs[ref].clearSelected();

      //     this.$nextTick(() => {
      //         let network = this.networks[this.currentTabs];
      //         this[network].selected = [row.item];
      //     });
      // }

      this.$refs["approve-withdraw-modal"].show();
    },

    showRejectWithdrawModal(id, network) {
      this.withdraw_id = [id];
      this.selectedNetwork = network;
      // if (type == 'single') {
      //     this.$refs[ref].clearSelected();

      //     this.$nextTick(() => {
      //         let network = this.networks[this.currentTabs];
      //         this[network].selected = [row.item];
      //     });
      // }

      this.$refs["reject-withdraw-modal"].show();
    },

    async approveWithdraw(ref) {
      this.isApproving = true;
      if (!this.twoFaCode || this.twoFaCode == "") {
        this.$swal({
          text: this.$t("Please fill in 2FA"),
          icon: "error",
        });
        this.isApproving = false;
        return;
      }

      // let network = this.networks[this.currentTabs];

      // let withdraw_id = this[network].selected.map(item => item.id);
      let params = {
        withdrawal_id: this.withdraw_id,
        action: "approve",
        google2fa: this.twoFaCode,
      };

      try {
        await api.apiCall(
          this.selectedNetwork.toLowerCase(),
          "POST",
          "/withdrawals/approve",
          params
        );

        this.$swal({
          text: this.$t("Approve success"),
          icon: "success",
        });

        this.fetchPendingTransactions();
        this.$refs[ref].hide();
        this.isApproving = false;
      } catch (error) {
        this.$swal({
          text: error.message,
          icon: "error",
        });
        this.$refs.approveWithdrawOtpInput.clearInput();
        this.twoFaCode = "";
        this.isApproving = false;
      }
    },

    async rejectWithdraw(ref) {
      this.isRejecting = true;
      if (!this.twoFaCode || this.twoFaCode == "") {
        this.$swal({
          text: this.$t("Please fill in 2FA"),
          icon: "error",
        });
        this.isRejecting = false;
        return;
      }

      // let network = this.networks[this.currentTabs];

      // let withdraw_id = this[network].selected.map(item => item.id);

      let params = {
        withdrawal_id: this.withdraw_id,
        action: "reject",
        google2fa: this.twoFaCode,
      };

      try {
        await api.apiCall(
          this.selectedNetwork.toLowerCase(),
          "POST",
          "/withdrawals/approve",
          params
        );

        this.$swal({
          text: this.$t("Reject success"),
          icon: "success",
        });

        // this[network].isUpdate = false;
        this.fetchPendingTransactions();
        this.$refs[ref].hide();
        this.isRejecting = false;
      } catch (error) {
        this.$refs.rejectWithdrawOtpInput.clearInput();
        this.$swal({
          text: error.message,
          icon: "error",
        });
        this.twoFaCode = "";
        this.isRejecting = false;
      }
    },

    onTwoFaComplete(value) {
      this.twoFaCode = value;
    },

    onBscRowSelected(items) {
      this.bsc.selected = items;
    },

    onTrxRowSelected(items) {
      this.trx.selected = items;
    },

    onEthRowSelected(items) {
      this.eth.selected = items;
    },

    changeTab(currentTabs) {
      console.log(currentTabs);
      this.fetchPendingTransactions(currentTabs);
      localStorage.setItem("currentApproveTab", currentTabs);
    },

    selectAllTransactions(networkTransactions) {
      // this.$refs.bscTransactions.selectAllRows();
      this.$refs[networkTransactions].selectAllRows();
    },

    unSelectAllTransactions(networkTransactions) {
      this.$refs[networkTransactions].clearSelected();
    },

    dateFormatter(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
      }
    },

    formatNum(num, decimal) {
      return coinHelper.formatNumberWithCommas(num, decimal);
    },
  },
};
</script>

<style>
@import "../css/styles.css";

.bg-cs-gray {
  background-color: #dcdcdc;
}

.radius-50 {
  border-radius: 50px;
}

.right-center {
  right: 20px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.trx-body {
  height: 100%;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.trx-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.trx-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* table */
.table-responsive {
  display: inline-table;
}

.trx-table {
  font-size: 13px;
  text-align: center;
  margin-top: 0.5rem;
  /* padding-left: 0.5rem */
}

.trx-table th,
.trx-table td {
  font-size: 16px;
  border: 1px !important;
}

.trx-table table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.trx-table td {
  border-bottom: none;
  padding: 10px;
  vertical-align: middle;
}

.trx-table tr {
  background-color: var(--light);
}

.trx-table thead tr {
  background-color: transparent;
}

.trx-table td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.trx-table td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.trx-table.b-table-has-details {
  background-color: aliceblue;
}

.transaction-rowClass:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.trx-table.b-table-details:hover {
  cursor: auto;
  background-color: white;
}

.approve .loading-container {
  min-height: 500px;
}

.approve .active-tab {
  background-color: var(--primary-10) !important;
  color: var(--primary) !important;
  border-radius: 20px;
}

.approve .inactive-tab {
  border: 1px solid var(--primary);
  margin-left: 20px;
  border-radius: 20px;
}

.approve .inactive-link {
  color: var(--primary);
}

.approve table th {
  text-align: center;
}

.approve table td {
  vertical-align: baseline;
}
</style>

<style scoped>
body {
  background-color: #f2f2f2;
}

.title {
  color: #33ad4a;
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}

hr {
  border: 3px solid white;
}

.data-info {
  background-color: white;
  border-radius: 10px;
  margin: 15px 5px;
  padding: 10px 15px;
}

.time {
  font-size: 12px;
}

.request-id {
  font-weight: bold;
  font-size: 15px;
  color: #797979;
}

.coin-amount {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  font-weight: bold;
  font-size: 20px;
}

.coin {
  display: flex;
  align-items: center;
}

.coin-img {
  margin-right: 10px;
  width: 35px;
}

.coin-network {
  display: flex;
  align-items: flex-end;
}

.network {
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 3px;
  color: #797979;
  font-weight: lighter;
}

.detail-table {
  font-weight: bold;
  color: #acacac;
  font-weight: normal;
}

.detail-table tr {
  vertical-align: text-top;
}

.detail-table td {
  padding-bottom: 10px;
}

.detail-subtitle {
  white-space: nowrap;
}

.colon {
  padding: 0 15px;
}

.address {
  word-break: break-all;
}

.button-icon {
  display: flex;
  justify-content: flex-end;
}

.button-icon img {
  margin-left: 50px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
